import React from 'react';
import styles from './HeaderActions.module.scss';
import { usePageUrl } from '@/helpers/use-page-url';
import { useEventProvider } from '@/helpers/event-context';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import clsx from 'clsx';

interface Props {
  onClick?: () => void;
}

export default function GetStartedButton({ onClick }: Props) {
  const { registerPageUrl } = usePageUrl();
  const { sendEvent } = useEventProvider();
  const isMobile = useIsMobile('(max-width: 576px)');

  return (
    <a
      className={clsx(styles.register, styles.button)}
      target="_blank"
      href={registerPageUrl()}
      rel="nofollow noreferrer"
      onClick={() => {
        if (onClick) {
          onClick();
        }
        sendEvent('intent_start_free');
      }}
    >
      {isMobile ? 'Start for Free' : 'Get Started Free'}
    </a>
  );
}
